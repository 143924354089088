<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Pratiche caricate</h4>
            Estrae tutti i contratti con data di caricamento compresa dal giorno al giorno selezionato
            <br><br>
            <hr>
        </div>
        <div class="row justify-center">

            <div class="col-12 col-md-4 q-mr-sm">
                <q-input outlined
                    v-model="dal_giorno"
                    mask="##/##/####"
                    label="Dal giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="dal_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
                <q-input outlined
                    v-model="al_giorno"
                    mask="##/##/####"
                    label="Al giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="al_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
            </div>
            <div class="col-12 col-md-4">
                <q-select
                    emit-value
                    map-options
                    outlined
                    v-model="stato_pratica"
                    :options="options_stato"
                    label="Stato del Contratto"
                />
                <br>
                <q-select
                    emit-value
                    map-options
                    outlined
                    v-model="compagnia"
                    :options="elenco_compagnie"
                    label="Compagnia"
                />
            </div>

            <div class="col-12 col-md-12" align="center">
                <br>
                <q-btn color="primary" label="Cerca" @click.native="onCercaNuovaProduzione"/>
                <br>
            </div>

            <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
                v-show="!is_loading"
                style="z-index: 1000;">
                <q-spinner-hourglass
                    size="4em"
                />
            </div>

            <div class="col-12 col-md-12">
                <q-table
                    title=""
                    :data="rows"
                    :columns="colonne"
                    color="primary"
                    row-key="name"
                    flat
                    :visible-columns="visibleColumns"
                    >
                    <template v-slot:top-right>
                        <q-btn
                        color="primary"
                        icon-right="archive"
                        label="ESPORTA IN CSV"
                        no-caps
                        @click="esportaTabella"
                        />
                    </template>
                </q-table>
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import { exportFile } from 'quasar';
    import QQButton from "@/components/QQButton.vue";

    function wrapCsvValue (val, formatFn) {
        let formatted = formatFn !== void 0
            ? formatFn(val)
            : val

        formatted = formatted === void 0 || formatted === null
            ? ''
            : String(formatted)

        formatted = formatted.split('"').join('""')
        /**
         * Excel accepts \n and \r in strings, but some other CSV parsers do not
         * Uncomment the next two lines to escape new lines
         */
        // .split('\n').join('\\n')
        // .split('\r').join('\\r')

        return `"${formatted}"`
    }

    export default {
        name: "PraticheCaricate",
        components: {
            QQButton
        },
        data() {
            return {
                dal_giorno: "",
                al_giorno: "",
                stato_pratica: "tutti",
                elenco_compagnie: [],
                compagnia: "tutti",
                visibleColumns: ['nominativo','unita_operativa','numero','compagnia','stato','decorrenza','scadenza','Premio','provvigioni'],
                options_stato: [
                    { label: "VISUALIZZA TUTTO", value: "tutti" },
                    { label: "DEFINITA", value: "DEFINITA" },
                    { label: "IN ATTESA DI LAVORAZIONE", value: "IN ATTESA DI LAVORAZIONE" },
                    { label: "IN LAVORAZIONE", value: "IN LAVORAZIONE" },
                    { label: "IN ATTESA INTERVENTO UFFICIO", value: "ATTESA INTERVENTO UFFICIO" },
                    { label: "IN ATTESA INTERVENTO BACKOFFICE", value: "ATTESA INTERVENTO BACKOFFICE" },
                    { label: "ANNULLATA", value: "ANNULLATA" }
                ]
            }
        },
        methods: {
            ...mapActions({
                fetchPraticheCaricate: "gestioneEstrazioni/fetchPraticheCaricate",
                fetchElencoCompagnie: "gestioneCatalogoProdotti/fetchElencoCompagnie"
            }),
            esportaTabella() {
                // naive encoding to csv format
                const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                    this.rows.map(row => this.colonne.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                    )).join(','))
                ).join('\r\n')

                const status = exportFile(
                    'pratiche_caricate-'+this.dal_giorno.replace("/","_")+'_'+this.al_giorno.replace("/","_")+'.csv',
                    content,
                    'text/csv'
                )

                if (status !== true) {
                    this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                    })
                }
            },
            async onCercaNuovaProduzione() {
                await this.fetchPraticheCaricate({dal_giorno: this.dal_giorno,al_giorno: this.al_giorno, stato: this.stato_pratica.replaceAll(" ","_"), compagnia: this.compagnia});

                if (this.rows.length === 0) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun dato identificato'
                    });
                }
            },
            onIndietroClicked() {
                this.$router.push({name : "Estrazioni"});
            }
        },
        computed: {
            ...mapFields("gestioneEstrazioni", {
                colonne: "colonne",
                rows: "righe"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                is_loading: state => state.gestioneEstrazioni.is_loading
            }),
            getDefaultDalGiorno() {
                var oggi = new Date();
                var mese = (oggi.getMonth() + 1)+"";
                var anno = oggi.getFullYear();

                if (parseInt(mese) > 12) {
                    mese -= 12;
                    mese += "";
                    anno++;
                }

                return "01/"+mese.padStart(2,'0')+"/"+anno;
            },
            getDefaultAlGiorno() {
                var oggi = new Date();
                var mese = (oggi.getMonth() + 1)+"";
                var anno = oggi.getFullYear();
                var giorno = "30";

                switch(mese) {
                    case 1:
                    case 3:
                    case 5:
                    case 7:
                    case 8:
                    case 10:
                    case 12: giorno = "31";
                    break;
                    case 2: giorno = "28";
                    break;
                }

                return giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            }
        },
        async mounted() {
            this.dal_giorno = this.getDefaultDalGiorno;
            this.al_giorno = this.getDefaultAlGiorno;
            this.elenco_compagnie = await this.fetchElencoCompagnie(true);
            this.elenco_compagnie.unshift( { label: "VISUALIZZA TUTTO", value: "tutti" });

           // console.log("Elenco compagjnie: ",this.elenco_compagnie);
        },
        activated()
        {
           this.colonne = [];
           this.rows = [];
        }
    }
</script>



